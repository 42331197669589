@import '../../assets/styles/brand-colors';
@import '../../assets/styles/font-styles';

.img-logo-login {
  position: absolute;
  width: 303px;
  height: 99px;
  left: 14px;
  top: 18px;
  background: url('../../assets/images/logo.png');
}

.title-login {
  //position: absolute;
  width: 490px;
  left: 95px;
  //top: 123px;
  font-family: 'Bebas Neue', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 86.5%;
  /* or 52px */
  color: var(--fourth-color);
  text-align: left;
}

.title-confirm {
  position: absolute;
  width: 490px;
  height: 104px;
  left: 95px;
  top: 123px;
  font-family: 'Bebas Neue', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 86.5%;
  /* or 52px */
  color: var(--fourth-color);
  text-align: left;
}

.subtitle-login {
  //position: absolute;
  width: 501px;
  height: 32px;
  left: 95px;
  //top: 227px;
  font-family: 'Poppins', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 32px;
  /* identical to box height */
  color: var(--fourth-color);
  text-align: left;
}

.subtitle-confirm {
  position: absolute;
  width: 501px;
  height: 32px;
  left: 95px;
  top: 340px;
  font-family: 'Poppins', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 32px;
  /* identical to box height */
  color: var(--fourth-color);
  text-align: left;
}

.login-container {
  position: absolute;
  width: 300px;
  height: 36px;
  left: 95px;
  top: 123px;
}

.confirm-container {
  position: absolute;
  width: 300px;
  height: 36px;
  left: 98px;
  top: 520px;

}

.form-control-login {
  box-sizing: border-box;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: var(--fourth-color);
  border: 1px solid var(--primary-color);;
  border-radius: 18px;
}

.form-control-login-text {
  width: 121px;
  height: 30px;
  left: 101px;
  top: 377px;
  font-family: 'Poppins', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */
  color: var(--fourth-color);
  padding-top: 10px;
}

.form-control-login-lost-password {
  left: 20.08%;
  right: 52.34%;
  top: 70.05%;
  bottom: 27.6%;
  font-family: 'Poppins', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  align-items: flex-end;
  color: var(--fourth-color);
  padding-left: 160px;
  margin-top: 4px;
  display: table-row;
}

.form-control-login-text2{
  align-items: center;
  display: flex;
  font-size: 20px;
  color: #fff;
  font-weight: bold;
}

.form-control-button-login-continue {
  box-sizing: border-box;
  width: 145px;
  height: 36px;
  left: 98px;
  top: 584px;
  border: 3px solid var(--fourth-color);
  border-radius: 18px;
  cursor: pointer;
}

.form-control-button-login-text {
  height: 21px;
  left: 33px;
  right: 38px;
  top: 10px;
  font-family: 'Poppins', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  text-align: center;
  color: var(--fourth-color);
  padding-top: 4px;
  cursor: pointer;
}

@media (max-width: 800px) {
  .title-login {
    //position: absolute;
    width: 282px;
    height: 105px;
    left: 43px;
    //top: 141px;
    font-family: 'Bebas Neue', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 86.5%;
    /* or 35px */
    color: var(--fourth-color);
  }
  .subtitle-login {
    //position: absolute;
    width: 242px;
    height: 54px;
    left: 43px;
    //top: 246px;
    font-family: 'Poppins', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 27px;
    color: var(--fourth-color);
  }

  .login-container {
    left: 8.89%;
    right: 7.78%;
    bottom: 32.12%;
  }
  .form-control-button-login-continue {
    box-sizing: border-box;

    width: 145px;
    height: 36px;
    left: 32px;
    top: 600px;
    border: 3px solid var(--primary-color);
    border-radius: 18px;
  }
  .form-control-button-login-text {
    height: 21px;
    left: 33px;
    right: 38px;
    top: 9px;
    font-family: 'Poppins', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    text-align: center;
    color: var(--primary-color);
  }
}


.form-control-50-login {
  height: 36px;
  border-radius: 18px;
  background: $white;
  border: 1px solid $pink;
  box-shadow: 0 0 2px rgba(208, 2, 27, 0.704044);
  width: 320px;
  font-size: 14px;
  padding-left: 10px;
  margin-bottom: 8px;
}

//Landscape phones and smaller
@media (MAX-WIDTH: 480PX) {
  .title-confirm {
    position: absolute;
    width: 300px;
    height: 192px;
    left: 30px;
    top: 213px;
    
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 48px;
    
    color: var(--fourth-color);
  }
  
}

@media (MAX-WIDTH: 767px) {
  .title-confirm {
    position: absolute;
    width: 300px;
    height: 192px;
    left: 30px;
    top: 213px;
    
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 48px;
    
    color: var(--fourth-color);
  }
  
  .subtitle-confirm {
    position: absolute;
    width: 266px;
    height: 168px;
    left: 30px;
    top: 415px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--fourth-color);
  }

  .confirm-container {
    position: absolute;
    width: 300px;
    height: 36px;
    top: 600px;
    display: flex;
  }
}

@media (MIN-WIDTH: 768px) and (MAX-WIDTH: 979px) {
  .title-confirm {
    position: absolute;
    width: 490px;
    height: 104px;
    left: 95px;
    top: 123px;
    font-family: 'Bebas Neue', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 60px;
    line-height: 86.5%;
    /* or 52px */
    color: var(--fourth-color);
    text-align: left;
  }
  
}
