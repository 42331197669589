.backdrop {
    height: 100%;
    background: rgba(0, 0, 0, 0.61);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2000;
    position: fixed;
}

.popup {
    position: absolute;
    z-index: 2001;
    left: 23%;
    background-color: var(--fourth-color);
    padding: 10px 30px;
    width: auto;
    border-radius: 20px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    .xross {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 768px) {
    .popup {
        width: 100%;
        padding: 10px 2px;
        left: 0px;
        left: 0% !important;
    }
}