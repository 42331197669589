.cambio-parrent {
    .popup {
        width: 26%;
        top: 38% !important;
        left: 38%;
        .cambioPop {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            height: 170px;
            div{
                display: block;
                p{
                    color: var(--cambio-color);
                    line-height: 130%;
                    font-size: 16px;
                    margin-bottom: 0rem;
                }
            }
            button {
                color: var(--fourth-color);
                border: none;
                padding: 5px 20px;
                border-radius: 5px;
                background: var(--cambio-background);
                &:focus {
                    outline: none;
                    border: none;
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        .popup {
            width: 100%;
            left: auto;
        }       
    }

    .cambio {
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--fourth-color);
        height: 60vh;
        padding: 10px;
        text-align: left;
        margin-top: 100px;
        .body {
            width: 50%;
            .head {
                margin-bottom: 30px;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                .title {
                    font-weight: 600;
                    font-size: 40px;
                    line-height: 60px;
                    text-align: left;
                    text-transform: uppercase;
                    color: var(--fourth-color);  
                    margin-bottom: 10px;  
                }
                .subTitle {
                    padding-left: 40px;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 36px;
                    text-align: left;
                    text-transform: lowercase;
                    color: var(--fourth-color);    
                }
                @media screen and (max-width: 768px) {
                    .subTitle {
                        padding-left: 0px;
                    }
                }
            }
            .field {
                margin-bottom: 30px;
                label {
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 27px;
                    color: var(--fourth-color);
                    margin-bottom: 10px;
                    text-align: left; 
                }
                input {
                    width: 100%;
                    height: 30px;
                    padding-left: 10px;
                    border: none;
                    border-bottom: 1px solid var(--fourth-color);
                    background: none;
                    border-radius: 0px;
                    color: var(--fourth-color);
                    &::placeholder{
                        font-size: 14px;
                        line-height: 16px;
                        color: var(--fourth-color);
                    }
                    &:focus{
                        outline: none;   
                        box-shadow: none;
                    }
                }
            }
            .submit {
                display: flex;
                margin-bottom: 30px;
                button {
                    color: var(--fourth-color);
                    background-color: var(--primary-color);
                    padding: 10px 35px;
                    border: none;
                    border-radius: 29px;
                    font-weight: 700;
                }
            }
        }
        @media screen and (max-width: 768px) {
            .body {
                width: 100%;
            }
        }
    }

}