.palate {
    padding: 5px 0px 20px;
    .palatehead {
        font-weight: 400;
        font-size: 20px;
        line-height: 113.5%;
        text-align: center;
        color: #7C8B87; 
        margin: 10px 0px;
        span {
            color: var(--primary-color);
        }   
    }
    .body {
        margin: 20px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid var(--black-color);
        border-radius: 9px;
        padding: 10px 20px;
        width: 100%;
        .palatebox {
            display: flex;
            flex-direction: column;
            width: 140px;
            margin-right: 10px;
            margin-bottom: 10px;
            .box {
                width: 100%;
                height: 118px;
                border-radius: 9px;
                margin-bottom: 10px;
            }
            input{
                width: 100%;
                border-radius: 20px;
                height: 30px;
                border: 1px solid #A19F9D;
                &::placeholder{
                    font-family: 'SegoeUI';
                    font-size: 14px;
                    line-height: 16px;
                    color: var(--dark-color);
                }
                &:focus{
                    outline: none;
                    box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.07), -4px -4px 13px #fff, 6px 6px 30px rgba(0, 0, 0, 0.05);
                }
            }
        }
        @media screen and (max-width: 768px) {
            .palatebox {
                margin-right: 0px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .body {
            padding: 20px 0px;
            flex-direction: column;
        }
    }
    .submit {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
        button {
            margin-right: 30px;
            margin-bottom: 15px;
            color: var(--fourth-color);
            padding: 10px 25px;
            border: none;
            border-radius: 20px;
        }
    }
}
@media screen and (max-width: 768px) {
    .palate {
        padding: 5px;
        .submit {
            flex-direction: column;
            button {
                margin-right: 0px;
                width: 90%;
            }
        }
    }
}