
/* The switch - the box around the slider */

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}


/* Hide default HTML checkbox */

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}


/* The slider */

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--primary-color);
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: var(--fourth-color);
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: var(--primary-color);
}

input:focus+.slider {
    box-shadow: 0 0 1px var(--primary-color);
}

input.checked+.slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
}


/* Rounded sliders */

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}


.swicth-tab{
    display: flex;
    align-items: center;
    span{
        font-size: 14px;
        line-height: 21px;
        color: #A19F9D;
        margin: 0px 5px !important;
    }
}