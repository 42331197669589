.editInfo.info {
    padding: 10px;
    display: flex;
    flex-direction: column;
    .image {
        padding: 5px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        img {
            border: 1px solid var(--black-color);
            border-radius: 9px;
        }
        .subtitle {
            margin: 5px 0px 10px;
            font-weight: 400;
            font-size: 20px;
            line-height: 113.5%;
            color: #7C8B87;            
        }
        button {
            color: var(--fourth-color);
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: var(--fourth-color);            
            background: var(--primary-color);
            border-radius: 20px; 
            padding: 5px 15px;  
            border: none;         
        }
    }
    .input {
        display: flex;
        flex-direction: column;
        .submit {
            text-align: center;
            margin: 10px 0px;
            button {
                color: var(--fourth-color);
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: var(--fourth-color);            
                background: var(--dark-color);
                border-radius: 20px; 
                padding: 5px 15px;   
                border: none;        
            }
        }
    }
}