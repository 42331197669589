.textoModal {
    color: black ;
    text-align: justify ;
}
.autorizacion{
    top: 20%;
}
.autorizaciones{
    display: flex;
    background-color: #e53935;
}
.si{
    display: inline-flex;
}
.checkAuto{
    padding-right: 10px;
}
.modal {
    display: block; /* Cambiar de 'none' a 'block' para mostrar el modal */
    position: fixed;
    z-index: 1051;
    width: 100%;
    height: auto !important;
    overflow: auto;  
    .modal-dialog {
      margin: 10% auto; /* Centra verticalmente el modal */
    }
  
    .modal-content {
      background-color: #fefefe;
      padding: 20px;
      width: 100%;
    }
  
    .modal-header {
        padding: 10px 16px;
        text-align: center;
        display: flex;
        flex-direction: column-reverse;
        font-weight: bold;
    }
  
    .modal-body {
      padding: 16px;
      border-top: black 2px solid;
    }
  
    .modal-footer {
      padding: 16px;
      color: white;
      display: flex;
      justify-content: flex-end;
  
      .btn {
        margin-left: 10px;
        padding: 8px 16px;
        border-radius: 4px;
        cursor: pointer;
  
        &.btn-primary {
          background-color: #4caf50;
          color: white;
        }
  
        &.btn-secondary {
          background-color: #f44336;
          color: white;
        }
      }
    }
  }

  .modal-footer {
    .btn {
      &.btn-primary {
        background-color: #4caf50; /* Color verde para el botón "Aceptar" */
        color: white;
        &:hover {
          background-color: #45a049; /* Cambio de color al pasar el mouse */
        }
      }
  
      &.btn-secondary {
        background-color: #f44336; /* Color rojo para el botón "Cancelar" */
        color: white;
        &:hover {
          background-color: #e53935; /* Cambio de color al pasar el mouse */
        }
      }
    }
  }