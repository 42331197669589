.components {
    background: transparent;
    color: var(--fourth-color);
    padding: 20px 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    div {
        margin: 10px;
        height: 100px;
        width: 75%;
        border-radius: 5px;
        display: flex;
        justify-content: space-around;
        div {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 10px;
        }
    }
}

button {
    border: none;
    border-radius: 5px;
    transition: all 0.5s ease;
    &:hover {
        transition: all 0.5s ease;
        box-shadow: var(--box-shadow);
    }
}

.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    p{
        text-transform: uppercase;
        margin: 0px;
        padding: 0px;
    }
}