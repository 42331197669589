nav.navbar{
    background: none;
}
.textoModal {
    color: black ;
    text-align: justify ;
}
.autorizacion{
    top: 20%;
}
.modal {
    display: block; /* Cambiar de 'none' a 'block' para mostrar el modal */
    position: fixed;
    z-index: 1051;
    width: 100%;
    height: auto !important;
    overflow: auto;  
    .modal-dialog {
      margin: 10% auto; /* Centra verticalmente el modal */
    }
  
    .modal-content {
      background-color: #fefefe;
      padding: 20px;
      width: 100%;
    }
  
    .modal-header {
        padding: 10px 16px;
        text-align: center;
        display: flex;
        flex-direction: column-reverse;
        font-weight: bold;
    }
  
    .modal-body {
      padding: 16px;
      border-top: black 2px solid;
    }
  
    .modal-footer {
      padding: 16px;
      color: white;
      display: flex;
      justify-content: flex-end;
  
      .btn {
        margin-left: 10px;
        padding: 8px 16px;
        border-radius: 4px;
        cursor: pointer;
  
        &.btn-primary {
          background-color: #4caf50;
          color: white;
        }
  
        &.btn-secondary {
          background-color: #f44336;
          color: white;
        }
      }
    }
  }

  .modal-footer {
    .btn {
      &.btn-primary {
        background-color: #4caf50; /* Color verde para el botón "Aceptar" */
        color: white;
        &:hover {
          background-color: #45a049; /* Cambio de color al pasar el mouse */
        }
      }
  
      &.btn-secondary {
        background-color: #f44336; /* Color rojo para el botón "Cancelar" */
        color: white;
        &:hover {
          background-color: #e53935; /* Cambio de color al pasar el mouse */
        }
      }
    }
  }
.si{
    display: inline-flex;
}
.loginfirm{
    width: 60%;
    margin: 0px 10%;
    .title-firma{
        font-family: 'Poppins', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        color: var(--fourth-color);
        padding: 10px 0px 0px 10px;
    }
    form section {
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .title-login {
        position: static;
        width: 100%;
        height: auto;
        left: 0px;
        top: 0px;
        font-family: 'Bebas Neue', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 60px;
        line-height: 86.5%;
        color: var(--fourth-color);
        text-align: left;
        padding: 10px;
    }
    .form-control-login-text {
        width: 100%;
        height: auto;
        left: 0px;
        text-align: left;
        top: 0px;
        font-family: "Poppins", serif;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        color: var(--fourth-color);
        padding-top: 10px;
    }
    .subtitle-login {
        position: static;
        width: 100%;
        height: auto;
        left: 0px;
        top: 0px;
        font-family: 'Poppins', serif;
        font-style: normal;
        font-weight: 500;
        font-size: 21px;
        line-height: 32px;
        /* identical to box height */
        color: var(--fourth-color);
        text-align: left;
        padding: 10px;
    }
    .login-container {
        text-align: left;
        position: static;
        width: 100%;
        height: auto;
        left: 0px;
        top: 0px;
        padding: 10px;
        select{
            height: 30px;
            border-radius: 18px;
            width: 50%;
            padding-left: 10px;
            margin-top: 5px;
            margin-bottom: 5px;
            border: 1px solid var(--dark-color);
            &:focus{
                outline: none;
            }
        }
        input{
            height: 30px;
            border-radius: 18px;
            width: 50%;
            padding-left: 10px;
            margin-top: 5px;
            margin-bottom: 5px;
            border: 1px solid var(--dark-color);
            &:focus{
                outline: none;
            }
        }
        @media (max-width: 768px) {
            select{
                width: 100%;
            }
            input{
                width: 100%;
            }
        }
    }
    .form-control-login-text {
        padding-left: 10px;
    }
    form .form-control-small-button{
        margin-left: 10px;
        transition: all 0.5s ease;
        &:hover{
            color: black;
        }
    }
    @media (max-width: 800px) {
        .login-container {
            background: none;
            border: none;
            box-shadow: none;
        }
        .title-firma{
            font-size: 14px;
            line-height: 20px;
        }
        .title-login {
            font-size: 36px;
            line-height: 40px;
        }
        .form-control-login-text {
            font-size: 16px;
            line-height: 22px;
        }
        .subtitle-login {
            font-size: 18px;
            line-height: 24px;
        }
        .form-control-50-credit {
            width: 90%;
            font-size: 14px;
        }
    }
}
@media (max-width:768px) {
    .loginfirm{
        width: 100%;
        padding: 10px;
    }
}