.switchParent {
    display: flex;
    align-items: center;
    .switchText {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: var(--black-color);
        margin-right: 10px;
    }
    
    .switch {
      position: relative;
      display: inline-block;
      width: 60px;
      height: 34px;
      input { 
        opacity: 0;
        width: 0;
        height: 0;
      }
      
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
      }
      
      .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: var(--fourth-color);
        -webkit-transition: .4s;
        transition: .4s;
      }
      
      input.checked + .slider {
        background-color: #2FD32B;
      }
      
      input:focus + .slider {
        box-shadow: 0 0 1px #2FD32B;
      }
      
      input.checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }
      
      /* Rounded sliders */
      .slider.round {
        border-radius: 34px;
      }
      
      .slider.round:before {
        border-radius: 50%;
      }
    }
    
}

