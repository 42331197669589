.catalog {
    display: flex;
    padding: 20px;
    .first-part {
        width: 40%;
    }
    .second-part {
        width: 60%;
        .fullTable {
            margin-bottom: 20px;
            padding: 10px 30px;
            .table{
                height:20%;
                clear:both;
                ul {
                    li {
                        text-align: left;
                    }
                }
                @media screen and (max-width: 768px) {
                    ul {
                        padding-left: 0rem;
                    }
                }
            }
            .contenedor {
                margin-left: 40px;
                margin-right: 5px;
                .izquierda{
                    margin-top: 0px;
                    margin-bottom: 0px;
                    margin-left: 0px;
                    margin-right:0px ;
                    width:45%; 
                    float:left;
                    position: relative;
                }
                .derecha{
                    margin-top: 12px;
                    margin-bottom: 0px;
                    margin-left: 0px;
                    margin-right: 0px;
                    width:45%;
                    float:right;
                    position: relative;
                    .Icono{
                        position: absolute;
                        width: 20px;
                        left: 12px;
                        top: 49%;
                        transform: translateY(-35%);
                    }
                }
            }
        }
    }
.userAdd{
        display: flex;
        justify-content: space-between;
        button{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 40%;
            border-radius: 20px;
            padding: 5px 15px;
            border: none;
            margin-bottom: 10px;
            margin-left: 0px;
        }
        @media screen and (max-width: 768px) {
            button{
                width: 100%;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .first-part {
            display: none;
        }
        .second-part {
            width: 100%;
            .fullTable {
                padding: 5px;
            }
        }
    }
}