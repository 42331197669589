.otpfunc {
    .setotp{
        padding: 20px 10px;
        p {
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            text-align: center;
            color: var(--dark-color);    
        }
        .otpset {
            margin: 20px 0px 10px;
            display: flex;
            justify-content: space-around;
            .otpnum {
                border: none;
                margin-right: 10px;
                background-color: #FD5B4FB8;
                color: var(--fourth-color);
                width: 40px;
                height: 78px;
                border-radius: 17px;
                font-size: 32px;
                padding-left: 10px;
            }
            .otpnum::-webkit-inner-spin-button{
                -webkit-appearance: none;
                -moz-appearance: textfield;
                appearance: none;
            }
        }
    }
    .submit {
        display: flex;
        justify-content: center;
        button {
            padding: 10px 25px;
            border-radius: 69px;
            border: 1px solid var(--primary-color);
            color: var(--primary-color); 
            &:focus{
                outline: 1px solid var(--primary-color);
                box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.07), -4px -4px 13px #fff, 6px 6px 30px rgba(0, 0, 0, 0.05);
            }
        }
    }
}