.New-User {
    margin-top: 20px;
    display: flex;
    .left-side{
        width: 60%;
        padding: 0px 60px;
        ul {
            display: flex;
            &:nth-child(3){
                margin-top: 30px;
            }
        }
        @media screen and (max-width:768px) {
            ul{
                padding: 0px 15px;
                flex-direction: column;
            }
        }
    }
    .input-error {
        border: 1px solid red !important;
        box-shadow: 0 0 2px red !important;
      }
    .right-side{
        width: 40%;
    }
    @media screen and (max-width: 768px) {
        .left-side{
            width: 100%;
            padding: 20px;
        }
        .right-side{
            display: none;
        }
    }
}