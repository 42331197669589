.loginPagare-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background: linear-gradient(to right, 
    #014f47,
    #01a39bb9,
    #00908e7a, 
    #adfdfc88), url('../../assets/images/background.png');
    background-size: cover;
    background-repeat: no-repeat;
  }
  .contenido{
    padding: 10% 15%;
  }
  
  .code-input {
    width: 2rem;
    height: 4rem;
    font-size: 1.5rem;
    text-align: center;
    margin: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 15px;
    outline: none;
    color: black !important;
  }
  p {
    color: #ffff !important;
    text-align: center !important;
  }
  .continue-button {
    margin-top: 1rem;
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    background-color: #016A64;
    color: #fff;
    border: none;
    border-radius: 20px;
    cursor: pointer;
  }