.perfildetail {
    padding: 20px 60px;
    .detail {
        display: flex;
        border: 1px solid var(--dark-color);
        border-radius: 39px;
        padding: 20px 40px;
        margin-bottom: 30px;
        li {
            display: flex;
            align-items: flex-end;
            width: 100%;
            margin-bottom: 10px;
            h4{
                margin-right: 10px;
                font-weight: 700;
                font-size: 20px;
                line-height: 27px;
                color: var(--black-color);
                margin-bottom: 0px;
                text-align: left;
            }
            p{
                font-weight: 400;
                font-size: 20px;
                line-height: 27px;
                color: var(--black-color);
                margin-bottom: 0px;
            }
            &:nth-child(2) {
                p {
                    color: #236EB6;
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        .detail {
            flex-direction: column;
            li {
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }
    .perfilFunc {
        width: 100%;
        margin: 25px 0px;
        padding-left: 0px;
        button {
            background: var(--primary-color);
            color: var(--fourth-color);
            display: flex;
            justify-content: space-between;
            width: 30%;
            padding: 10px 25px;
            border-radius: 20px;
            border: none;
        }
        @media screen and (max-width: 768px) {
            button {
                width: 100%;
            }
        }
    }
    .table {
        li {
            text-align: left;
        }
        .itemsPerPage {
            span {
                margin-top: 4px !important;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .perfildetail {
        padding: 10px 15px;
    }
}