.mis-pagare-container {
  margin: 0 auto;
  padding: 20px;
}
button {
  background-color: #00908E;
  color: #ffffff;
  border: none;
  padding: 0.8rem 2.5rem;
  cursor: pointer;
  font-size: 1rem;
  width: 30%;
  margin-right: 10px;
}

.btn-cargar-solicitudes {
  border: 1px solid;
  border-color: black;
}

.modal {
  display: block; /* Asegúrate de que esté visible */
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.modal-content-ANI {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  display: flex;
  flex-direction: column;
}

.close-button {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close-button:hover,
.close-button:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.modal-buttons{
  display: flex !important;
  justify-content: flex-end !important;
  margin-top: 5% !important;
}
.cabecera-modal{
  display: flex;
  justify-content: space-between;
}
p{
  color: black;
  text-align: justify;
}
input{
  width: 50%;
  padding-left: 30px;
  border-radius: 0px;
  height: 30px;
  border: 1px solid;
}
.cargar-solicitudes-btn {
  background-color: #00908e;
  color: #fff;
  padding: 8px 16px;
  border-radius: 15px;
  cursor: pointer;
  margin-bottom: 16px;
  margin-top: 16px;
  justify-content: center;
  display: flex;
  width: 20vw;
  margin-left: 1vw;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 6px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f2f2f2;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.pagination span {
  margin: 0 4px;
  cursor: pointer;
}

.total-pagare {
  margin-top: 16px;
  text-align: center;
}

.table-container {
  border-color: black;
  border: 1px solid;
}

/* Estilos para los botones adicionales */
.acciones-btns {
  display: flex;
  justify-content: center;
  align-items: center;

  .acciones-btn {
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 8px;
    font-size: 14px;

    &.visible {
      background-color: #4caf50;
      color: white;
      width: 100%;
    }

    &.oculto {
      background-color: #f44336;
      color: white;
      display: none;
    }
  }
}