.otp {
    height: 100vh;
    .doc {
        width: 80%;
        height: 60%;
        background-color: var(--dark-color);
        margin-bottom: 30px;
        margin: 0 auto;
        .document {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 90%;
            margin: 0 auto;
            background-color: var(--fourth-color);
            p {
                font-weight: 500;
                font-size: 30px;
                line-height: 34px;
                text-align: center;
                color: var(--black-color);    
            }
        }
    }
    .submit {
        display: flex;
        justify-content: center;
        button {
            padding: 5px 35px;
            border-radius: 69px;
            border: 1px solid var(--primary-color);
            color: var(--primary-color);
            &:focus {
                outline: 1px solid var(--primary-color);
            }
        }
    }
}