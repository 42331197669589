.thank-you-view {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background: linear-gradient(to right, 
    #014f47,
    #01a39bb9,
    #00908e7a, 
    #adfdfc88), url('../../assets/images/background.png');
  }
  
  .content {
    text-align: center;
    margin-right: 2rem;
  }
  
  h1 {
    color: #ffff;
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  p {
    color: #ffff;
    margin-bottom: 2rem;
  }
  
  button {
    background-color: #00908E;
    color: #ffffff;
    border: none;
    padding: 0.8rem 1.5rem;
    border-radius: 10px;
    cursor: pointer;
    font-size: 1rem;
    width: 30%;
  }
  
  img {
    width: 100%;
    height: auto;
  }