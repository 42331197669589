.usertable li:nth-child(2){
    svg{
        left: auto;
        right: 15px;
        top: 36px;
    }
}
@media (max-width: 768px) {
    li{
        width: 100%;
        margin-bottom: 10px;
    }
}
.usertable .responsiveAuto{
    ul{
        display: flex;
        li{
            width: 100%;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: -0.08px;
            color: #343741;
            border-bottom: 1px solid #D3DAE6;
            padding: 5px 10px;
            span{
                width: 8px;
                height: 8px;
                border-radius: 50%;
                display: inline-block;
                margin-right: 5px;
            }
            button{
                background: var(--primary-color);
                border-radius: 100px;
                border: none;
            }
        }
        li.firstItem{
            color: #006BB4;
        }
        li.listHeader{
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: -0.24px;
            color: #1A1C21;
        }
    }
}

.usertable .delete-box{
    position: relative;
    svg{
        position: absolute;
        top: 0px;
        left: 0px;
    }
}

@media (max-width: 768px) {
    .usertable .responsiveAuto{
        width: 400%;;
    }
}