.pagos {
    .submit {
        display: flex;
        justify-content: center;
        button {
            background: #3D57EE;
            color: var(--fourth-color);
            padding: 10px 25px;
            border: none;
            border-radius: 9px;
        }
    }
}