// ApplicantInfoForm.scss

$cyan-color: #00b3b3;
$border-color: #ccc;
$background-color: #fff;
$text-color: #333;

.applicant-info-form {
  max-width: 75vw;
  margin: 0 auto;
  padding: 20px;
  border: 2px solid $cyan-color;
  border-radius: 8px;
  background-color: $background-color;

  h2 {
    color: $cyan-color;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .form-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;

    .form-group {
      &.full-width {
        grid-column: span 2;
      }

      label {
        display: block;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 5px;
        color: $text-color;
      }

      input {
        width: 100%;
        padding: 8px;
        border: 1px solid $border-color;
        border-radius: 4px;
        font-size: 14px;

        &:focus {
          outline: none;
          border-color: $cyan-color;
        }
      }
    }
  }

  .payment-method {
    margin-top: 20px;

    p {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 10px;
      color: $text-color;
    }

    .radio-group {
      display: flex;
      gap: 20px;

      label {
        display: flex;
        align-items: center;
        cursor: pointer;

        input[type="radio"] {
          margin-right: 5px;
        }

        span {
          font-size: 14px;
        }
      }
    }
  }
}