.backdrop {
    height: 100%;
    background: rgba(0, 0, 0, 0.61);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2000;
    position: fixed;
}

.perfilfun {
    position: absolute;
    left: 23%;
    z-index: 2001;
    background-color: var(--fourth-color);
    padding: 10px 30px;
    width: 60%;
    .xross {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 20px;
    }
    .perfhead {
        ul{
            display: flex;
            justify-content: space-between;
            margin-bottom: 30px;
            p{
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                color: #A19F9D;    
            }
            button {
                background: #00908EB4;
                color: var(--fourth-color);
                font-size: 16px;
                line-height: 24px;
                padding: 10px 35px; 
                border: none;
                border-radius: 39px;      
            }
            &:first-child {
                li{
                    &:first-child {
                        width: 40%;
                    }
                    @media screen and (max-width: 768px) {
                        &:first-child {
                            width: 100%;
                        }
                    }
                }
            }
            &:last-child {
                justify-content: flex-end;
            }
            @media screen and (max-width:768px) {
                button {
                    width: 100%;
                    margin-left: 10px;
                    margin-right: 10px;
                }
            }
        }
        @media screen and (max-width: 768px) {
            ul {
                flex-direction: column;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .perfilfun {
        width: 100%;
        padding: 10px 2px;
        left: 0px;
        .perfhead {
            ul {
                padding-left: 1rem;
                padding-right: 1rem;
                button {
                    width: 90%;
                }
            }
        }
    }
}