.user-detail {
    display: flex;
    .first-part {
        width: 60%;
        padding: 20px;
        .detail {
            margin-top: 40px !important;
            width: 70%;
            margin: 0 auto;
            ul {
                width: 100%;
                padding-left: 1rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 10px;
                li {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                    h4 {
                        margin-right: 20px;/* Nombre: */
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 16px;
                        color: var(--black-color);
                        margin-bottom: 0px;
                    }
                    p {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        color: var(--black-color);
                        margin-bottom: 0px;
                    }
                    label {
                        margin-bottom: 0px;
                    }
                    select {
                        width: 70%;
                    }
                    @media screen and (max-width: 768px) {
                        select {
                            width: 100%;
                        }
                    }
                }
            }
            ul.perfil {
                margin-bottom: 30px;
            }
            @media screen and (max-width: 768px) {
                ul {
                    flex-direction: column;
                    padding-left: 0rem;
                    li {
                        align-items: flex-start;
                    }
                    li.switchParent {
                        align-items: center;
                    }
                }
            }
        }
        @media screen and (max-width: 768px) {
            .detail {
                width: 100%;
            }
        }
    }
    .second-part {
        width: 40%;
        margin-left: 30px;
    }
    @media screen and (max-width: 768px) {
        .first-part {
            width: 100%;
            padding: 10px;
        }
        .second-part {
            display: none;
        }
    }
}