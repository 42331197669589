.input {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    margin-right: 30px;
    width: 100%;
    label{
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: var(--black-color);
        margin-bottom: 10px;      
        margin-right: 10px;      
        margin-left: 10px;      
    }
    li.Descripcion
    {
        width: 100%;
        padding-left: 30px;
        border-radius: 20px;
        height: 30px;
        border: 1px solid #A19F9D;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
        font-size: 14px;
        line-height: 16px;
        // color: var(--dark-color);
        color: black;
        outline: none;
        box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.07), -4px -4px 13px #fff, 6px 6px 30px rgba(0, 0, 0, 0.05);
    }
}

.input.down {
    flex-direction: column;
}

.select {
    display: flex;
    align-items: flex-start;
    position: relative;
    margin-bottom: 10px;
    margin-right: 30px;
    width: 100%;
    label{
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: var(--black-color); 
        margin-bottom: 10px;
        margin-right: 10px;       
        margin-left: 10px;      
    }
    select{
        width: 100%;
        appearance: none;
        padding-left: 30px;
        border-radius: 20px;
        height: 30px;
        border: 1px solid #A19F9D;
        &:focus{
            outline: none;
            box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.07), -4px -4px 13px #fff, 6px 6px 30px rgba(0, 0, 0, 0.05);
        }
    }
    svg{
        position: absolute;
        top: 10px;
        right: 20px;
    }
}

.select.down {
    flex-direction: column;
    svg{
        position: absolute;
        top: 38px;
        right: 20px;
    }
}

@media screen and (max-width: 768px) {
    .input{
        margin-right: 0px;
        flex-direction: column;
    }
    .select{
        margin-right: 0px;
        flex-direction: column;
        svg {
            top: 25px;
        }
    }
}