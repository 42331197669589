.pagos {
    .submit {
        display: flex;
        justify-content: center;
    }
    button[type="button"] {
        display: block;
        width: 30%;
        background-color: #00908eb4;
        color: #fff;
        border: none;
        border-radius: 15px;
        font-size: 16px;
        cursor: pointer;
        margin-top: 5vh !important;
        height: 6vh !important;
  
        &:hover {
          background-color: #00908E;
        }
      }
}