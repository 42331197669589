.editInfo {
    padding: 10px;
    display: flex;
    form {
        display: flex;
    }
    .image {
        padding: 5px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-right: 10px;
        img {
            border: 1px solid #000000;
            border-radius: 9px;
        }
        .subtitle {
            margin: 5px 0px 10px;
            font-weight: 400;
            font-size: 20px;
            line-height: 113.5%;
            color: #7C8B87;            
        }
        button {
            color: var(--fourth-color);
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: var(--fourth-color);            
            background: var(--primary-color);
            border-radius: 20px; 
            padding: 5px 15px;  
            border: none;         
        }
    }
    .info {
        ul {
            display: flex;
            li {
                width: 100%;
                margin-right: 10px;
            }
        }
        .attributos {
            h4 {
                font-weight: 400;
                font-size: 20px;
                line-height: 113.5%;
                text-align: center;
                color: var(--primary-color); 
                margin-bottom: 10px;   
            }
            p {
                font-weight: 400;
                font-size: 20px;
                line-height: 113.5%;
                color: #7C8B87; 
                margin-bottom: 10px;   
                text-align: left;
            }
            .table{
                .edit{
                    display: flex;
                    justify-content: flex-end;
                }
                .table-body{
                    height: 200px;
                    width: 100%;
                    overflow: auto;
                    .body {
                        height: auto;
                        width: auto;
                        ul {
                            display: flex;
                            border-bottom: 1px solid #CCCCCC;
                            margin-bottom: 0px;
                            li {
                                width: 100%;
                                font-family: 'Inter';
                                font-style: normal;
                                font-weight: 600;
                                font-size: 12px;
                                line-height: 18px;
                                letter-spacing: -0.24px;
                                color: var(--dark-color);
                                text-align: left;
                            }
                            li.Numeracion {
                                width: 10%;
                                font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
                                    
                                color: var(--primary-color);                               
                            }
                            li.TipoAtributo {
                                width: 45%;
                                font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
                                                            
                            }
                            input.Descripcion
                                {
                                    width: 100%;
                                    padding-left: 30px;
                                    border-radius: 20px;
                                    height: 30px;
                                    border: 1px solid #A19F9D;
                                    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
                                    font-size: 14px;
                                    line-height: 16px;
                                    // color: var(--dark-color);
                                    color: black;
                                    outline: none;
                                    box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.07), -4px -4px 13px #fff, 6px 6px 30px rgba(0, 0, 0, 0.05);
                                }
                            
                        }
                    }
                }
            }
            .submit {
                display: flex;
                justify-content: flex-end;
                button {
                    border: none;
                }
            }
        }
        .LiStyle{
            text-align: left;
        }
        .InDataEntrada
        {
            width: 100%;
            padding-left: 30px;
            border-radius: 20px;
            height: 30px;
            border: 1px solid #A19F9D;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
            font-size: 14px;
            line-height: 16px;
            // color: var(--dark-color);
            color: black;
            outline: none;
            box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.07), -4px -4px 13px #fff, 6px 6px 30px rgba(0, 0, 0, 0.05);
        }
    }
    @media screen and (max-width: 768px) {
        form {
            flex-direction: column;
        }
    }
}

.editInfo.attribute {
    .info {
        .attributos {
            width: 500px;
            .submit {
                justify-content: space-between;
            }
        }
        @media screen and (max-width: 500px) {
            .attributos {
                width: 300px;
            }
        }
        
    }
}

@media screen and (max-width: 768px) {
    .editInfo {
        flex-direction: column;
        .image {
            margin-right: 0px;
        }
        .info {
            ul {
                flex-direction: column;
                li {
                    margin-right: 0px;
                }
            }
            .body {
                ul {
                    flex-direction: row;
                    li {
                        margin-right: 0px;
                    }
                }
            }
        }
    }
}