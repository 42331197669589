.datos {
    display: flex;
    .first-part {
        width: 40%;
        margin-right: 100px;
    }
    .second-part {
        width: 60%;
        padding: 20px;
        .upper-part {
            margin-bottom: 40px;
            display: flex;
            .left-side {
                margin-right: 10px;
                border: 1px solid var(--black-color);
                border-radius: 9px;
                padding: 40px 20px 10px 20px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 30%;
                .logotext {
                    margin-top: 20px;
                    p {
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 113.5%;
                        text-align: center;
                        color: var(--datos-color); 
                        margin-bottom: 5px;   
                    }
                    h6 {
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 113.5%;
                        text-align: center;
                        color: var(--primary-color);    
                    }
                }
            }
            .right-side {
                padding: 10px;
                width: 70%;
                p {
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 113.5%;
                    text-align: left;
                    color: var(--datos-color);    
                }
                h6 {
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 113.5%;
                    text-align: left;
                    color: var(--primary-color);    
                }
            }
            @media screen and (max-width: 768px) {
                .left-side{
                    margin-right: 0px;
                    width: 100%;
                    margin-bottom: 20px;
                }
                .right-side{
                    width: 100%;
                }
            }
        }
        .lower-part {
            padding: 20px;
            border: 1px solid var(--black-color);
            border-radius: 9px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .upside{
                display: flex;
                justify-content: space-between;
                .box {
                    width: 131px;
                    height: 118px;
                    border-radius: 9px;
                    margin-right: 10px;
                    margin-bottom: 10px;
                }
            }
            .downside{
                p {
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 113.5%;
                    text-align: center;
                    color: var(--datos-color);
                    margin-bottom: 5px; 
                }
                h4 {
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 113.5%;
                    text-align: center;
                    color: var(--primary-color);
                    cursor: pointer;  
                }
            }
            @media screen and (max-width: 768px) {
                .upside {
                    flex-direction: column;
                    align-items: center;
                    .box {
                        margin-right: 0px;
                    }
                }
            }
        }
        @media screen and (max-width: 768px) {
            .upper-part {
                flex-direction: column;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .first-part {
            display: none;
        }
        .second-part {
            width: 100%;
        }
    }
}