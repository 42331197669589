.menu-agent {
    .title {
        font-family: 'Bebas Neue', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 60px;
        line-height: 115.5%;
        text-align: center;
        color: var(--primary-color);
    }
    .subtitle {    
        font-family: 'Poppins', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 128%;
        text-align: center;
        color: var(--catalog-color);
        margin-bottom: 20px;
    }
    .items {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        .item {
            padding: 10px;
            width: 178px;
            height: 155px;
            border: 3px solid var(--black-color);
            border-radius: 10px;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            .item-text {
                width: 80%;
                margin: 5px auto 0px;
            }
            margin: 20px;
        }
    }
    .items {
        .item.active {
            border: 3px solid var(--primary-color);
            .item-text {
                color: var(--primary-color);
            }
            svg {
                color: var(--primary-color);
                path {
                    fill: currentColor;
                }
            }
        }
    }
    .items {
        .item:hover {
            border: 3px solid var(--primary-color);
            .item-text {
                color: var(--primary-color);
            }
            svg {
                color: var(--primary-color);
                path {
                    fill: currentColor;
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        .items {
            flex-direction: column;
        }
    }
} 

.flex {
    display: flex;
    justify-content: space-around;
    button {
        font-size: 12px;
        height: 30px;
        margin: 10px 5px;
        border: none;
        border-radius: 5px;
        color: var(--fourth-color);
        background-color: var(--primary-color);
        cursor: pointer;
    }
}