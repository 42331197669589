#check{
    ul{
    margin-right: 60px;
    pointer-events: none;
}  
}
label {
    font-weight: 500;
    color: #333;
    cursor: pointer;
}
div p{
    color: black !important;
}
#check:checked{
    accent-color: #7d2ae8;
}  

#check:checked{
    accent-color: #7d2ae8;
}  

.UserDoc{
    text-align: left;
    padding: 50px 100px;
    .UserDocument{
        @media (max-width: 768px) {
            button{
                margin-right: auto;
                margin-left: 10px;
            }
        }
        h4{
            text-align: center;
            font-weight: 500;
            font-size: 36px;
            line-height: 54px;
            color: var(--primary-color);
        }
        .UserTable{
            padding: 20px 0px;
            display: flex;
            .first-part{
                width: 100%;
                margin-right: 20px;
            }
            .first-part.only{
                width: 100%;
            }
            .second-part{
                width: 100%;
            }
            @media screen and (max-width: 768px) {
                .first-part{
                    margin-right: 0px;
                    order: 1;
                }
                .second-part{
                    order: 0;
                }
            }
            .search form{
                margin: 0px 0px 20px;
                display: flex;
                justify-content: space-between;
            }
            @media (max-width: 768px) {
                .search form{
                    flex-direction: column;
                }
            }
        }
        @media screen and (max-width: 768px) {
            .UserTable{
                flex-direction: column;
            }
        }
    }
}
.adminDoc {
    .UserTable {
        .first-part {
            .userAdd {
                .search {
                    form {
                        margin: 0px;
                        li {
                            width: 100%;
                            input {
                                height: 30px;
                                border-radius: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.UserTable li{
    width: 45%;
    position: relative;
    list-style: none;
    select{
        width: 100%;
        appearance: none;
        padding-left: 30px;
        border-radius: 20px;
        height: 30px;
        &:focus{
            outline: none;
            box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.07), -4px -4px 13px #fff, 6px 6px 30px rgba(0, 0, 0, 0.05);
        }
    }
    input{
        width: 100%;
        padding-left: 30px;
        border-radius: 0px;
        height: 30px;    
        border: 1px solid var(--dark-color);
        color: var(--dark-color);
        &::placeholder{
            font-family: 'SegoeUI';
            font-size: 14px;
            line-height: 16px;
            color: var(--dark-color);
        }
        &:focus{
            outline: none;
            box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.07), -4px -4px 13px #fff, 6px 6px 30px rgba(0, 0, 0, 0.05);
        }
    }
    svg{
        position: absolute;
        top: 10px;
        left: 10px;
    }
}
.UserTable li.filter{
    svg{
        position: absolute;
        top: 10px;
        right: 10px;
        left: auto;
    }
}
.UserTable .pageNum li svg{
    position: static;
}

.UserDoc .UserDocument .userAdd{
    display: flex;
    justify-content: space-between;
    button{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 40%;
        border-radius: 20px;
        padding: 5px 15px;
        border: none;
        margin-bottom: 10px;
        margin-left: 0px;
    }
    @media screen and (max-width: 768px) {
        button{
            width: 100%;
        }
    }
}
@media screen and (max-width: 768px) {
    .userAdd{
        flex-direction: column;
    }
}

select{
    width: 100%;
    appearance: none;
    padding-left: 30px;
    border-radius: 20px;
    height: 30px;
    &:focus{
        outline: none;
        box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.07), -4px -4px 13px #fff, 6px 6px 30px rgba(0, 0, 0, 0.05);
    }
}
input{
    width: 100%;
    padding-left: 30px;
    border-radius: 20px;
    height: 30px;
    &::placeholder{
        font-family: 'SegoeUI';
        font-size: 14px;
        line-height: 16px;
        color: #A19F9D;
    }
    &:focus{
        outline: none;
        box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.07), -4px -4px 13px #fff, 6px 6px 30px rgba(0, 0, 0, 0.05);
    }
}
@media (max-width: 768px) {
    .UserTable li{
        width: 100%;
        margin-bottom: 10px;
    }
}
@media (max-width: 768px) {
    .UserDoc{
        padding: 20px 10px;
        margin-top: 10px;
        .UserDocument{
            margin-top: 10px;
        }
    }
}

.search-result{
    ul{
        display: flex;
        justify-content: space-between;
        align-items: center;
        li{
            height: 30px;
            width: 200px;
        }
        li.name{
            height: Auto;
            width: Auto;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: -0.08px;
            color: #006BB4;
            border-bottom: 1px solid #D3DAE6;
        }
        li.action{
            color: var(--fourth-color);
            button{
                padding: 5px 25px;
                border: none;
                border-radius: 20px;
            }
        }
        margin-bottom: 30px;
    }
    .search-info{
        ul{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            li{
                margin-left: 15px;
                display: flex;
                width: 100%;
                h2{
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 16px;
                    color: var(--black-color);
                    margin-right: 15px;           
                }
                p{
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    color: var(--black-color);               
                }
            }
        }
        input.mycheck2 {
            display: inline-flex;
            align-items: center;
            vertical-align: middle;
            cursor: pointer;
            background-color: #fff;
            color: #fff;
            background-image: url("data:image/svg+xml,%3csvg viewBox='-2 0 20 20' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
            width: 26px;
            height: 26px;
            appearance: none;
            border: 2px solid #888;
            background-position: 0 -2rem;
            background-size: 100%;
            background-repeat: no-repeat;
            transition: all 0.3s ease-in-out;
        }
        .mycheck2:checked {
            background-color: rgb(75, 156, 13);
            color: rgb(75, 156, 13);
            background-position: 0 0;
        }
        @media screen and (max-width: 768px) {
            ul{
                flex-direction: column;
                li{
                    margin-bottom: 5px;
                }
            }
        }
    }
}
