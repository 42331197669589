.policies-container {
  display: flex;
  background-color: #f5f5f5;

  .boton{
    justify-content: center;
    display: flex;
  }

  .image-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .form-container {
    flex: 2;
    padding-left: 30px;
    padding-right: 30px;

    h2 {
      font-size: 24px;
      margin-bottom: 20px;
      color: #00908E;
      text-align: left;
      padding-top: 8vh;
    }

    p {
      text-align: left;
      color: black;
    }
    .form-row{
      display: flex;
      justify-content: space-around;
      padding-top: 10px;
    }

    .form-group {
      display: flex;
      flex-wrap: wrap;
      width: 40%;
      padding-top: 5px;

      label {
        display: block;
        font-weight: bold;
        margin-bottom: 5px;
      }

      input,
      select {
        width: 100%;
        border: 1px solid #ccc;
        font-size: 16px;
      }
    }

    button[type="button"] {
      display: block;
      width: 50%;
      background-color: #00908ec2;
      color: #fff;
      border: none;
      border-radius: 15px;
      font-size: 16px;
      cursor: pointer;
      margin-top: 4vh;
      height: 7vh;

      &:hover {
        background-color: #00908E;
      }
    }
  }
}