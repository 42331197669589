.buttonAgregarCoRe{
    margin: 0!important;
}
.txtBlac{
    color: black;
}
.cambiarEstado{
    background-color: #00908E;
    color: white;
    text-align: center;
}
.textcenter{
    text-align: center;
}
.textright{
    text-align: right;
}
.textleft{
    text-align: left;
}
.divTipRiesgo{
    width: 350px;
}
.falsedivEstado{
    display: none;
    transition: 0.3s;
}
.detail{
    padding: 30px;
    .header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 5px 0px 15px;
        h2{
            font-weight: 500;
            font-size: 36px;
            line-height: 54px;
            color: var(--primary-color);
        }
    }
   
    p.estadoMedio{
        font-size: 30px;
        line-height: 36px;
        letter-spacing: -1.5px;
        color: var(--fourth-color);
        border: none;
        border-radius: 38px;
        background-color: #FFB533;
        padding: 5px 15px;
        text-align: center;
    }
    
    p.estadoNegado{
        font-size: 30px;
        line-height: 36px;
        letter-spacing: -1.5px;
        color: var(--fourth-color);
        border: none;
        border-radius: 38px;
        background-color: var(--primary-color);
        padding: 5px 15px;
        text-align: center;
    }

    p.estadoAceptado{
        font-size: 30px;
        line-height: 36px;
        letter-spacing: -1.5px;
        color: var(--fourth-color);
        border: none;
        border-radius: 38px;
        background-color: #2FD32B;
        padding: 5px 15px;
        text-align: center;
    }
    @media (max-width: 768px) {
        .header{
            flex-direction: column;
            button{
                margin-bottom: 20px;
                margin-right: auto;
            }
            h2{
                margin-bottom: 10px;
            }
        }
        p.estadoMedio{
            margin-bottom: 10px;
            font-size: 20px;
            width: 100%;
        }
        p.estadoNegado{
            margin-bottom: 10px;
            font-size: 20px;
            width: 100%;
        }
        p.estadoAceptado{
            margin-bottom: 10px;
            font-size: 20px;
            width: 100%;
        }
    }
    .body{
        display: flex;
        .sidebar{
            box-sizing: border-box;
            background: var(--fourth-color);
            border: 1px solid var(--fourth-color);
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 20px;
            position: relative;
            text-align: left;
            width: 300px;
            height: 100%;
            h4{
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                text-align: center;
                color: var(--fourth-color);
                width: 100%;
                background: var(--dark-color);
                border-radius: 20px;
                position: absolute;
                top: 0px;
                left: 0px;
                padding: 5px 0px;
            }
            ul{
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                padding: 5px 10px;
                margin-top: 0px;
                .rank{
                    display: inline-block;
                    text-align: center;
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    color: var(--fourth-color);
                    margin-right: 10px;
                }
                .text{
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 19px;
                    color: var(--dark-color);    
                    margin-right: 10px;
                    text-align: left;
                    width: 67%;
                }
                .abcilon{
                    display: inline-block;
                    width: 17px;
                    text-align: center;
                    height: 17px;
                    font-size: 12px;
                    border-radius: 50%;
                    color: var(--fourth-color);
                }
                &:first-of-type{
                    margin-top: 40px;
                }
            }
        }
        @media (max-width: 768px) {
            .sidebar{
                margin: 10px auto;
            }
        }
    }
    @media (max-width: 768px) {
        .body{
            flex-direction: column;
        }
    }
}
.nopadding{
    padding: 0px;
    padding-left: 4px;
    font-size: small;
}
.mainDetail{
    padding: 0px 20px;
    .box{
        width: 100%;
        padding: 20px 10px;
        border: 1px solid #A19F9D;
        border-radius: 20px;
        margin-bottom: 20px;
        ul{
            margin: 10px 5px;
        }
        @media (max-width: 768px) {
            ul{
                margin: 10px 0px;
            }
        }
        .title{
            display: flex;
            margin-bottom: 10px;
            position: relative;
            li{
                font-weight: 700;
                font-size: 13px;
                line-height: 19px;
                color: var(--primary-color);
                width: auto;
                &:first-child{
                    margin-right: 20px;
                }
            }
            svg{
                margin-left: 5px;
            }
            .edit{
                position: absolute;
                top: 0px;
                right: 10px;
            }
        }
        .redBox{
            border-radius: 44px;
            border: 1px solid var(--primary-color);
            padding: 10px 15px;
            margin: 20px 10px;
        }
        .sub-box{
            text-align: center;
            h3{
                font-weight: 500;
                font-size: 20px;
                line-height: 30px;
                color: #00908E;
                margin: 10px;
                // padding-left: 2rem;  
            }
            h5{
                margin: 10px;
                padding-left: 2rem;
                font-weight: 500;
                font-size: 15px;
                line-height: 22px;
                color: var(--dark-color);
            }
            ul{
                display: flex;
                margin: 10px;
                justify-content: center;
                .redic{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    margin-right: 70px;
                    .title{
                        font-size: 24px;
                        line-height: 30px;
                        color: var(--primary-color);
                    }
                }
                @media (max-width: 768px) {
                    .redic{
                        margin-right: 0px;
                    }
                }
                .mapping{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 15px;
                    width: 100%;
                    .check{
                        margin-bottom: 10px;
                        padding: 15px;
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        align-items: center;
                        border-right: 1px solid #1B9543;
                        svg{
                            margin-bottom: 5px;
                        }
                        p{
                            font-size: 12px;
                            line-height: 16px;
                            color: #1B9543;
                            margin-bottom: 15px;
                        }
                        h6{
                            font-weight: 700;
                            font-size: 12px;
                            line-height: 16px;
                            text-align: center;
                            color: var(--black-color);    
                        }
                    }
                    .score{
                        padding: 15px;  
                    }
                }
                @media (max-width: 768px) {
                    .mapping{
                        flex-direction: column;
                        .check{
                            border-right: none;
                            border-bottom: 1px solid #1B9543;
                        }
                    }
                }
                li{
                    padding: 5px 12px;
                    text-align: left;
                    label{
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 18px;
                        color: var(--black-color);
                        margin-bottom: 5px;
                    }
                    p{
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        color: var(--black-color);
                        margin-left: 5px;
                        input{
                            border: 1px solid #A19F9D;
                            padding-left: 10px;
                            height: 30px;
                            &:focus{
                                outline: none;
                            }
                        }
                    }
                }
            }
            ul.w-100{
                li{
                    width: 100%;
                }
            }
            ul.submit{
                display: flex;
                justify-content: space-between;
                button{
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    text-align: center;
                    color: var(--fourth-color);
                    background: #979797;
                    border-radius: 20px;
                    padding: 5px 20px;
                    border: none;
                }
            }
            @media (max-width: 768px) {
                ul{
                    flex-direction: column;
                }
                ul.submit{
                    flex-direction: row;
                }
            }
            .pillInfo{
                display: flex;
                margin: 15px 0px;
                padding: 0px 2rem;
                justify-content: space-between;
                p{
                    display: inline-block;
                    font-size: small;
                    line-height: 24px;
                    color: var(--black-color); 
                    margin: 10px 0px;                       
                }
            }
            @media (max-width: 768px) {
                .pillInfo{
                    flex-direction: column;
                }
            }
        }
        .submit{
            display: flex;
            justify-content: flex-end;
            button{
                padding: 5px 15px;
                background: var(--primary-color);
                color: var(--fourth-color);
                border: none;
                border-radius: 182px;
                margin-right: 30px;
                width: 180px;
            }
            @media (max-width: 768px) {
                button{
                    margin-right: 0px;
                    width: 100%;
                }
            }
        }
        .submitStartG{
            display: flex;
            justify-content: flex-start;
            margin: 10px 0px;
            padding-left: 2rem;
            button{
                padding: 8px 15px;
                background: #1B9543;
                color: var(--fourth-color);
                border: none;
                border-radius: 182px;
                margin-right: 30px;
            }
            @media (max-width: 768px) {
                button{
                    margin-right: 0px;
                    width: 100%;
                }
            }
        }
        // .submitStartR{
        //     display: flex;
        //     justify-content: flex-start;
        //     margin: 10px 0px;
        //     padding-left: 2rem;
        //     button{
        //         padding: 8px 50px;
        //         background: var(--primary-color);
        //         color: var(--fourth-color);
        //         border: none;
        //         border-radius: 182px;
        //         margin-right: 30px;
        //     }
        //     @media (max-width: 768px) {
        //         button{
        //             margin-right: 0px;
        //             width: 100%;
        //         }
        //     }
        // }
        .submitDis{
            display: flex;
            justify-content: flex-end;
            button{
                padding: 5px 15px;
                background: var(--dark-color);
                color: var(--fourth-color);
                border: none;
                border-radius: 182px;
                margin-right: 30px;
                width: 180px;
            }
            @media (max-width: 768px) {
                button{
                    margin-right: 0px;
                    width: 100%;
                }
            }
        }
        .submitHide{
            display: flex;
            justify-content: flex-end;
            button{
                padding: 5px 15px;
                background: #979797;
                color: var(--fourth-color);
                border: none;
                border-radius: 182px;
                margin-right: 30px;
                width: 180px;
            }
            @media (max-width: 768px) {
                button{
                    margin-right: 0px;
                    width: 100%;
                }
            }
        }
        @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
          
          .loading {
            animation: spin 1s infinite linear;
          }
        .submitDisable{
            display: flex;
            justify-content: flex-end;
            button{
                padding: 5px 15px;
                background: var(--dark-color);
                color: var(--fourth-color);
                border: none;
                border-radius: 182px;
                margin-right: 30px;
                width: 180px;
            }
            @media (max-width: 768px) {
                button{
                    margin-right: 0px;
                    width: 100%;
                }
            }
        }
        .documents{
            margin: 10px 0px;
            padding: 0px 30px;
            .redBox{
                border-radius: 5px;
                border: 1px solid var(--primary-color);
                padding: 10px 15px;
                margin: 20px 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
               
                p{
                    font-size: 12px;
                    line-height: 16px;
                    text-align: center;
                    color: var(--primary-color); 
                    margin: 5px;  
                   
                }
            }
        }
        .documentsGarantia{
            margin: 10px 0px;
            padding: 0px 30px;
            
            .redBox1{
                border-radius: 5px;
                border: 1px solid var(--primary-color);
                padding: 10px 15px;
                margin: 20px 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;           
                p{
                    font-size: 12px;
                    line-height: 16px;
                    text-align: center;
                    color: var(--primary-color); 
                    margin: 5px;  
                    width: 50%;                  
                }
            }
        }
        .table{
            width: 100%;
            margin: 0 auto;
        }
        @media (max-width: 768px) {
            .documents{
                flex-direction: column;
            }
            .table{
                width: 100%;
            }
            .submitStartG{
                padding-left: 0;
            }
            .submitStartR{
                padding-left: 0;
            }
        }
    }
}
@media (max-width: 768px) {
    .mainDetail{
        width: 100%;
        padding: 0px;
        margin: 15px 0px;
    }
}

@media (max-width: 768px) {
    .detailCredit{
        padding: 0px 15px 15px 15px;
    }
}
.redBox.check{
    background: var(--primary-color);
    svg{
        height: 50px;
    }
    p{
        color: var(--fourth-color) !important;
    }
}
.redBox1.check{
    background: var(--primary-color);
    svg{
        height: 50px;
    }
    p{
        color: var(--fourth-color) !important;
    }
}
