.back-forward{
    display: flex;
    justify-content: space-between;
    margin: 20px 40px;
    button{
        margin-bottom: 15px;
    }
}
@media screen and (max-width: 768px) {
    .back-forward{
        flex-direction: column;
    }
}