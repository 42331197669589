.rectangle-change-password {
  /* Rectangle 355 */
  box-sizing: border-box;
  left: 25.08%;
  right: 58.91%;
  top: 52.47%;
  bottom: 23.7%;
  border: 3px solid var(--black-color);
  border-radius: 10px;
  cursor: pointer;
}

.rectangle-menu-solicitud {
  box-sizing: border-box;
  left: 43.05%;
  right: 40.94%;
  top: 52.47%;
  bottom: 23.7%;
  border: 3px solid var(--dark-color);
  border-radius: 10px;
  cursor: pointer;
}

.menu-text {
  left: 45.62%;
  right: 43.52%;
  top: 68.23%;
  bottom: 26.95%;
  font-family: 'Poppins', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: var(--dark-color);
}

.menu-text-change-password {
  /* Cambio de contraseña */
  position: absolute;
  left: 26.8%;
  right: 61.09%;
  top: 68.23%;
  bottom: 29.56%;
  font-family: 'Poppins', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: var(--black-color);
}

.menu-text-credit {
  /* Gestionar crédito */
  position: absolute;
  left: 63.44%;
  right: 25.62%;
  top: 67.97%;
  bottom: 27.08%;
  font-family: 'Poppins', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: var(--primary-color);
}

.vector-money {
  position: absolute;
  left: 49.53%;
  right: 47.42%;
  top: 58.2%;
  bottom: 34.11%;
}

.vector-lock {
  position: absolute;
  left: 30.78%;
  right: 64.77%;
  top: 56.38%;
  bottom: 34.38%;
}

.vector-agent {
  position: absolute;
  left: 65.94%;
  right: 28.12%;
  top: 56.77%;
  bottom: 33.33%;
}

.rectangle-menu-credit {
  box-sizing: border-box;
  position: absolute;
  left: 60.86%;
  right: 23.12%;
  top: 52.21%;
  bottom: 23.96%;
  border: 3px solid var(--primary-color);
  border-radius: 10px;
  cursor: pointer;
}

.subtitle-menu {
  position: absolute;
  left: 21.56%;
  right: 19.06%;
  top: 40.36%;
  bottom: 52.86%;
  font-family: 'Poppins', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 128%;
  /* or 31px */
  text-align: center;
  color: var(--catalog-color);

}

.title-welcome {
  position: absolute;
  left: 27.27%;
  right: 23.52%;
  top: 32.42%;
  bottom: 64.84%;
  font-family: 'Bebas Neue', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 115.5%;
  /* or 69px */
  text-align: center;
  color: var(--primary-color);

}

@media (max-width: 800px) {
  .rectangle-change-password {
    /* Rectangle 355 */
    left: 31.11%;
    right: 31.11%;
    top: 40.5%;
    bottom: 44.38%;
    border-radius: 10px;
    cursor: pointer;
  }
  .rectangle-menu-solicitud {
    left: 31.11%;
    right: 31.11%;
    top: 58.12%;
    bottom: 26.75%;
    border-radius: 10px;
    cursor: pointer;
  }

  .rectangle-menu-credit {
    box-sizing: border-box;
    position: absolute;
    left: 31.11%;
    right: 31.11%;
    top: 75.75%;
    bottom: 9.12%;
    border: 3px solid var(--primary-color);
    border-radius: 10px;
    cursor: pointer;
  }
  .title-welcome {
    position: absolute;
    left: 17.5%;
    right: 13.89%;
    top: 24.25%;
    bottom: 69.25%;
    font-family: 'Bebas Neue', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 128%;
    /* identical to box height, or 51px */
    text-align: center;
    color: var(--primary-color);
  }
  .subtitle-menu {
    position: absolute;
    left: 1.11%;
    right: 1.11%;
    top: 30%;
    bottom: 63.5%;
    font-family: 'Poppins', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 128%;
    /* or 23px */
    text-align: center;
    color: var(--catalog-color);
  }
  .vector-money {
    position: absolute;
    left: 46.39%;
    right: 46.67%;
    top: 61.75%;
    width: 40px;
    height: 40px;
    bottom: 33.38%;
  }
  .vector-lock {
    position: absolute;
    left: 46.72%;
    right: 45%;
    top: 43%;
    width: 40px;
    height: 40px;
    bottom: 51.12%;
  }
  .vector-agent {
    position: absolute;
    left: 46.06%;
    right: 43.06%;
    top: 78.62%;
    width: 40px;
    height: 40px;
    bottom: 15%;
  }
  .menu-text {
    position: absolute;
    left: 36.94%;
    right: 37.22%;
    top: 68.12%;
    bottom: 28.75%;
    font-family: 'Poppins', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 125.5%;
    /* or 13px */
    text-align: center;
    color: var(--dark-color);
  }
  .menu-text-change-password {
    /* Cambio de contraseña */
    position: absolute;
    left: 35.28%;
    right: 36.39%;
    top: 50.5%;
    bottom: 48%;
    font-family: 'Poppins', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 125.5%;
    /* or 13px */
    text-align: center;
    color: var(--black-color);
  }
  .menu-text-credit {
    /* Gestionar crédito */
    position: absolute;
    left: 36.94%;
    right: 36.94%;
    top: 85.75%;
    bottom: 11%;
    font-family: 'Poppins', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 125.5%;
    /* or 13px */
    text-align: center;
    color: var(--primary-color);
  }
  .btn-menu-back {
    position: absolute;
    left: 3.89%;
    right: 81.09%;
    top: 14.62%;
    bottom: 78.38%;
    cursor: pointer;
  }
}

@media (min-width: 801px) {
  .menu-text-change-password {
    font-size: 16px;
  }
  
  .menu-text-credit {
    font-size: 16px;
  }

  .menu-text {
    font-size: 16px;
  }

  
  .title-welcome {
   
    font-size: 50px;
}
}

.flex-container-menu-agent {
  top: 68.23%;
  display: flex;
  justify-content: center;
  background-color: DodgerBlue;
}

.flex-container-menu-agent > div {
  background-color: var(--menu-agent-back1);
  width: 100px;
  margin: 10px;
  text-align: center;
  line-height: 75px;
  font-size: 30px;
}

@media (max-height: 620px) {
  .menu-text-change-password {
    font-size: 9px;
  }

  .vector-money {
    position: absolute;
    
    width: 40px;
    height: 40px;
  }
  .vector-lock {
    position: absolute;
  
    width: 40px;
    height: 40px;

  }
  .vector-agent {
    position: absolute;
    width: 40px;
    height: 40px;
  }
  
  .menu-text-credit {
    font-size: 9px;
  }

  .menu-text {
    font-size: 9px;
  }

  
  .title-welcome {
   
    font-size: 50px;
}

.title-welcome {
  
  font-size: 25px;
}
.subtitle-menu {
  
  font-size: 16px;
  
}
}