.pdfbody2 {
    .head{
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 20px;
        .text{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 50%;
            h2{
                font-size: 18px;
            }
            .extra{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-right: 0px;
                padding-left: 0px;
                width: 30%;
                margin-top: 20px;
                li{
                    width: 100%;
                    text-align: center;
                    padding: 5px;
                    border: 1px solid #000;
                    height: 30px;
                }
            }
        }
    }
    .subhead {
        margin-top: 20px;
        ul {
            width: 100%;
            display: flex;
            margin-bottom: 10px;
            text-align: left;
            padding: 0px 150px;
            li {
                margin-right: 50px;
                text-align: left;
                width: 100%;
                border: none;
            }
        }
    }
    ul{
        padding-left: 0px;
        width: 100%;
        display: flex;
        margin-bottom: 0px;
        li{
            margin: 0px;
        }
    }
    li {
        font-weight: 600;
        text-align: left;
        height: 30px;
        border: 1px solid #333333;
        padding-left: 10px;
    }
    li.w-10 {
        width: 10%;
    }
    li.w-20 {
        width: 20%;
    }
    li.w-25 {
        width: 25%;
    }
    li.w-30 {
        width: 30%;
    }
    li.w-40 {
        width: 40%;
    }
    li.w-50 {
        width: 50%;
    }
    li.w-60 {
        width: 60%;
    }
    li.w-70 {
        width: 70%;
    }
    li.w-80 {
        width: 80%;
    }
    li.w-90 {
        width: 90%;
    }
    li.w-100 {
        width: 100%;
    }
    li.tc {
        justify-content: center;
    }
    .pdfbody {
        h3{
            text-align: center;
            color: #ffffff;
            background: #00a8ec;
            font-size: 32px;
            font-weight: 700;
            margin: 0px;
            margin-top: 30px;
        }
        h6{
            text-align: center;
            line-height: 150%;
        }
        p{
            font-size: 14px;
            line-height: 150%;
            text-align: justify;
        }
        .trademark {
            display: flex;
            align-items: flex-end;
            height: 300px;
            .signed{
                width: 16%;
                margin-right: 20px;
                .sign{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    height: 250px;
                    .signature{
                        width: 100%;
                        height: 80%;
                        border-bottom: 1px solid #000;
                    }
                    .signature.blk{
                        border: 1px solid #000;
                    }
                    p{
                        text-align: left;
                        font-size: 14px;
                        line-height: 150%;
                    }
                }
            }
        }
    }
}